import revive_payload_client_4sVQNw7RlN from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/cleavr/forkedrecipes.app/releases/20241010013016413/.nuxt/sentry-client-config.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg
]